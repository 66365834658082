import { useSpring, animated } from 'react-spring';
import { useRect } from '@reach/rect';
import { calc, transform, useReducedMotion } from "../../../../src/examples/make-it-springy";
import LiveCode from "../../../../src/components/live-code";
import React from 'react';
export default {
  useSpring,
  animated,
  useRect,
  calc,
  transform,
  useReducedMotion,
  LiveCode,
  React
};